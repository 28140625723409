import { useEffect, useState } from 'react';
import 'assets/scss/styles.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import gsap from "gsap";
import KonvaPlugin from "greensock-plugin/KonvaPlugin";
import useIsMobileView from 'hooks/useIsMobileView';
import ComingSoon from 'pages/ComingSoon/ComingSoon';
import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import Security from 'pages/LegalAndSecurity/Security/Security';
import TermsOfService from 'pages/LegalAndSecurity/PrivacyPolicyAndTerms/TermsOfService';
import PrivacyPolicy from 'pages/LegalAndSecurity/PrivacyPolicyAndTerms/PrivacyPolicy';
import Solutions from 'pages/Solutions/Solutions';
import AnnouncementBanner from 'components/AnnouncementBanner/AnnouncementBanner';
import MSA from "./pages/LegalAndSecurity/PrivacyPolicyAndTerms/MSA";
import SLA from "./pages/LegalAndSecurity/PrivacyPolicyAndTerms/SLA";
import MSA_Canada from "./pages/LegalAndSecurity/PrivacyPolicyAndTerms/MSA_Canada";

// .env variables can be access like the following
// const AWS_POINT = process.env.REACT_APP_AWS_POINT;

// uncomment below if coming soon page is needed
// import ComingSoon from './pages/ComingSoon/ComingSoon';
 
const App = () => {
    const COMING_SOON = process.env.REACT_APP_COMING_SOON;
    const [isMobile, setIsMobile] = useState(true);
    // Use our custom hook for viewports.
    const isMobileView = useIsMobileView();
    
    useEffect(() => {
        // Registers the gsap plugin for use with Konva canvas
        gsap.registerPlugin(KonvaPlugin);

        // Set Desktop or Mobile viewport 
        setIsMobile(isMobileView);
        
        return () => {
            //console.log('cleanUp App.js');
        }
        
    },[isMobileView]);


    const comingSoonPage = <Routes>
                                <Route path="/:lang" element={<ComingSoon isMobile={isMobile} />}></Route>
                            </Routes>
                            
    const app = <>
                    {/*<AnnouncementBanner  isMobile={isMobile} />*/}
                    <Routes>
                        
                        <Route path="/" element={<Home isMobile={isMobile} />}></Route>
                        <Route path="/about" element={<About  isMobile={isMobile} />}></Route>
                        <Route path="/solutions" element={<Solutions  isMobile={isMobile} />}></Route>
                        {/*Privacy Policy, Terms, and Security*/}
                        <Route path="/privacy" element={<PrivacyPolicy  isMobile={isMobile} />}></Route>
                        <Route path="/msa" element={<MSA  isMobile={isMobile} />}></Route>
                        <Route path="/msa/canada" element={<MSA_Canada  isMobile={isMobile} />}></Route>
                        <Route path="/sla" element={<SLA  isMobile={isMobile} />}></Route>
                        <Route path="/security" element={<Security  isMobile={isMobile} />}></Route>
                        <Route path="/terms" element={<TermsOfService  isMobile={isMobile} />}></Route>

                    </Routes>
                </>

    return (
    
        <BrowserRouter>
            {COMING_SOON === "true" && comingSoonPage}
            {COMING_SOON !== "true" && app}
        </BrowserRouter>

    );
}

export default App;
